
#home {
  padding-bottom: 17%;
}

.home-image {
  max-width: 36%;
  padding: 4%;
}

.home-container {
  display: flex;
  align-items: center;
  
}



.home-text {
  
  text-align: left;
  font-size: 1.8rem;
  padding: 3%;
  width: 46%;
  margin-top: 5%;
  letter-spacing: -0.5px;
}


.home-text p:first-of-type {
  margin-bottom: -40px; 
  color: #AAAAAA;
}


.bold-name {
  font-weight: 300;
  font-size: 4rem;
}

.second-p {
  font-size: 3rem;
  
}

.third-p {
  color: #AAAAAA;
}

.home-text .home-strong {
  color: #FFFFFF;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  
}

.social-icons > * {
  margin-right: 15px;
}


@media only screen and (min-width: 280px) and (max-width: 540px) {
  
  #home {
    padding-bottom: 25%;
  }
  .home-image {
    display: none;
  }

  .home-text {
    
    padding-top: 17%;
    width: 90%;
    padding-left: 6%;
    font-size: 1.7rem;
  }

  .home-text p:first-of-type {
    margin-bottom: -40px; 
  }

  .bold-name {
    font-weight: 300;
    font-size: 3rem;
  }

  .social-icons {
    margin-top: 15%;
  }

}

@media only screen and (min-width: 541px) and (max-width: 900px) {
 
  #home {
    padding-bottom: 25%;
  }
  .home-image {
    display: none;
  }

  .home-text {
    padding-top: 15%;
    width: 60%;
    padding-left: 8%;
    font-size: 2rem;  
  } 

  .second-p {
    font-size: 3.1rem;
  } 

  .social-icons {
    margin-top: 15%;
  }
} 

@media only screen and (min-width: 901px) {
  .home-text {
    padding-left: 5%;
  }

  .home-image {
    
    margin-right: 5%;
  }
}