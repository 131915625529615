.footer {
    
    padding: 3rem;
    background-color: #15202B;
    display: flex;
    justify-content: center;
    position: relative;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 15px;
}

.footer a {
    color: yellow;
    text-decoration: none;
}

@media (max-width: 480px) {

    .footer {
        font-size: 11px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}