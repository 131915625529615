.project-detail {
  
  color: BLACK;
  display: flex;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.project-detail-container {
  
  background-color: #15202B;
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-bottom: 10%;
  
}

.project-item {
  padding-top: 40px;
  width: 80%;
}

.project-page-container {
  background-color: #15202B;

}

.recent-projects-title {
  font-size: 24px; 
  padding-top: 50px; 
  font-weight: 900;
  
}

.project-detail {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}


.recent-projects-title {
  display: flex;
  justify-content: center;
  margin-top: 20px; 
  font-size: 2rem; 
  align-items: center;
  color: #ffffff;

}


.project-info {
  font-size: 1.2rem
  
}

.gallery-container {
  margin-top: 5%;
  width: 50%;
  margin-left: 2%;

}

.content-container {
  
  margin-top: 4%;
  margin-left: 3%;
  text-align: left;
  width: 40%; 
  
}

.project-tech-stack {
  font-weight: 700;
  
}

.project-description {
  font-weight: 500;
}

.project-date {
  color: #8899a6;
  font-weight: 700;
  margin-top: -15px;
  font-size: 15px;

}

.project-icons {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 15px;
  
}

@media (min-width: 1200px){

  .project-item {
    width: 85%;
    padding: 7%;
  }
  
  .project-detail{
    padding-bottom: 4%;
  }
}

 @media (max-width: 1200px){

  .project-item {
    width: 86%;
  }

  .project-detail-container {
    padding-bottom: 20%;
  }

  .project-detail {
    
    flex-direction: column;
    align-items: center; 
    
  }
    
  .gallery-container {
    
      margin-right: 3%;
      width: 95%;
     
    }

   .content-container {
      width: 94%;
      margin-right: 2%;
    }

    .project-detail {
      height: auto;
      flex-direction: column;
      align-items: center; 
      padding-bottom: 7%;
   }
    
  } 








 