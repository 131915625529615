@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;700;900&display=swap');



body {
  background-color: #121212;
  color: white;
  font-family: 'Rubik', sans-serif;
}




