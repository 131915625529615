#about {
    
    background-color: #121212;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 6%;
}

.about-text {
  max-width: 86%;
}

.about-box {
  
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #212121;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #FFFFFF;
}

.about-paragraph {
  font-size: 19px;
  color: black;
}

.about-me {
  
  font-weight: 900;
  color: #FFFFFF;
  
  
  
}
    
.about-container {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    padding-top: 5%;
  }
   
@media (max-width: 900px){
  .about-me {
    padding-left: 3%;
  }
}

