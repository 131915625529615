.navbar {
  
    padding: 2rem;
    background-color: #15202B;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .navbar a {
    text-decoration: none;
    list-style: none;
    color: white;
  }

  .nav-links li {
    margin: 0 1.3rem;
    transition: all .3s ease-in-out; 
    }

  .nav-links li:hover{
    transform: scale(1.1);
}

  .nav-links ul {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px; 
    list-style: none;
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 480px) {
    .navbar {
      padding: 1rem 3rem 1rem 1rem; 

    }
  
    .nav-links li {
      margin: 0 0.5rem; 
      font-size: 20px; 
    }
  }